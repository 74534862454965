import React, { useState, useEffect } from 'react'
import styles from './Header.module.scss'
import logo from '../../../assets/logos/unicef-white-blue.png'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { Container, Row } from '@kogk/common'
import { Link } from '@kogk/gatsby-theme-base'
import cn from 'classnames'
import {
  NavigationDesktop,
  NavigationMobile,
  Burger
} from '@cmp/site'
import { useGlobalData } from '@src/data/global-data'

export default () => {
  const { t } = useLanguage()
  const { mainMenu } = useGlobalData()
  const [isActive, setIsAcitive] = useState(false)

  useEffect(() => {
    document.body.style.overflowY = isActive ? 'hidden' : ''
  })

  const setAcitve = () => {
    const body = document.body
    body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome,

    setIsAcitive(!isActive)
  }

  return (
    <header>
      <Container fluid className='m-lg-0 pr-lg-0'>
        <Row className='d-flex'>
          <Link
            className={styles.logo}
            to='/'
          >
            <img src={logo} alt='unicef home' />
          </Link>

          <div
            className={cn(
              styles.nav,
              'd-flex align-items-center justify-content-lg-between'
            )}
          >
            <div className='d-none d-xl-flex h-100'>
              <NavigationDesktop items={mainMenu} />
            </div>

            <div className='d-flex justify-content-xl-end justify-content-between w-100'>
              <Link
                className='yellow-link yellow-link--navbar'
                to={t(`footer|yellow link`)}
              >
                {t(`footer|taka thatt label`)}
              </Link>
              <div className='d-xl-none d-flex align-items-center pr-2'>
                <Burger onClick={() => setAcitve()} active={isActive} />
              </div>
            </div>

          </div>
        </Row>
        {isActive &&
          <Row>
            <div className='d-xl-none d-flex'>
              <NavigationMobile items={mainMenu} />
            </div>
          </Row>
        }
      </Container>
    </header>
  )
}
