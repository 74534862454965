import React from 'react'
import { Container, Col } from '@kogk/common'
import { Link } from '@kogk/gatsby-theme-base'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import styles from './Footer.module.scss'
import { useGlobalData } from '@src/data/global-data'
import logo from '../../../assets/logos/unicef-white-stacked.png'
import cn from 'classnames'
import {
  Facebook,
  Instagram,
  Youtube,
  Twitter
} from '../../../assets/icons'

export default () => {
  const { t } = useLanguage()

  const { mainMenu } = useGlobalData()

  return (
    <footer>
      <Container fluid className={styles.footer}>
        <div className='row'>
          <Col col={{ xl: 10 }} offset={{ xl: 1 }} className='d-flex flex-column flex-xl-row align-items-center justify-content-between px-lg-0'>
            <Link to='/'>
              <img className={styles.logo} src={logo} alt='unicef frontpage' />
            </Link>
            <div className=' d-flex align-items-center flex-column flex-lg-row'>
              {mainMenu && mainMenu.map((link, key) => {
                return <Link
                  key={key}
                  className='nav-links mr-lg-4 py-2 py-lg-0'
                  to={link.url}>
                  {link.title}
                </Link>
              })}
            </div>
            <div className='p-0 d-flex justify-content-center justify-content-lg-end'>
              <Link
                className={cn('button button--normal yellow-link yellow-link--footer', styles.yellowLink)}
                to={t(`footer|yellow link`)}
              >
                {t(`footer|taka thatt label`)}
              </Link>
            </div>
          </Col>
      
          <div className={cn('offset-lg-1 pt-3 pt-lg-4 mt-2 col-xl-10', styles.border)} />
        </div>
        <div className='row pt-4 mt-3 mt-lg-2 text-center text-xl-left'>
          <div className='col-xl-10 offset-xl-1 d-flex flex-column flex-xl-row justify-content-between'>
            <div className=' p-0 pb-3 pb-lg-0'>
              <p className={styles.title}>
                {t(`footer|title`)}
              </p>
              <div className={styles.info}>
                {t(`footer|kennitala label`)}
                <br />
                <strong>{t(`footer|kennitala`)}</strong>
              </div>
              <div className={styles.info}>
                {t(`footer|bank account label`)}
                <br />
                <strong>{t(`footer|bank account`)}</strong>
              </div>
            </div>
            <div className=' pb-2 pb-lg-0'>
              <div className={styles.links}>
                <Link className='link link--on-black' to={`mailto:${t(`footer|email`)}`}>{t(`footer|email`)}</Link>
              </div>
              <div className={styles.links}>
                <Link className='link link--on-black' to={`tel:${t(`phone`)}`}>{t(`footer|phone`)}</Link>
              </div>
              <div className={styles.links}>
                <Link to={t('contact info| address link')} className='link link--on-black'>{t(`footer|address`)}</Link>
              </div>
            </div>
            <div className=' mb-4 mt-3 mt-lg-0 d-flex flex-column align-items-center align-items-xl-end'>
              <div className='pb-2 mb-2'>
                <Link className='mx-3 mr-md-0' to={t(`social media|facebook`)}>
                  <span className={styles.icons}>
                    <Facebook />
                  </span>
                </Link>
                <Link className='mx-3 mr-md-0' to={t(`social media|instagram`)}>
                  <span className={styles.icons}>
                    <Instagram />
                  </span>
                </Link>
                <Link className='mx-3 mr-md-0' to={t(`social media|twitter`)}>
                  <span className={styles.icons}>
                    <Twitter />
                  </span>
                </Link>
                <Link className='mx-3 mr-md-0' to={t(`social media|youtube`)}>
                  <span className={styles.icons}>
                    <Youtube />
                  </span>
                </Link>
              </div>
              <div className={styles.links}>
                <Link to={t('footer|postlist label')} className='link link--on-black'>{t('footer|postlist label')}</Link>
              </div>
              <div className={styles.links}>
                <Link to={t(`footer|personuvernd link`)}className='link link--on-black'>{t('footer|personuvernd label')}</Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  )
}
