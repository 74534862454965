import React, { useContext, useMemo } from 'react'
import {
  LanguageProvider,
  langCompare,
  useLanguage
} from '@kogk/gatsby-plugin-i18n'
import { graphql, useStaticQuery } from 'gatsby'
import { useMediaQuery } from '@kogk/common'
import '@src/data/prismic-fragments/menu'
import '@src/data/prismic-fragments/strings'
import { breakpointSm, breakpointMd } from './breakpoints.module.scss'
import { splitCardHeadings } from '@src/utils'

const Ctx = React.createContext({
  altUrls: []
})

const useBootstrapBreakpoints = () => {
  const isMobile = useMediaQuery(
    `screen and (max-width: ${breakpointSm - 1}px)`,
    false
  )
  const isTablet = useMediaQuery(
    `screen and (min-width: ${breakpointSm}px) and (max-width: ${breakpointMd -
      1}px)`,
    false
  )

  // if it's eg. print or some other condition, a width check can be unreliable
  // so we'll try to default to desktop
  const isDesktop = !isMobile && !isTablet

  return { isMobile, isTablet, isDesktop }
}

export const GlobalDataProvider = ({ language, altUrls = [], children }) => {
  const results = useStaticQuery(query)
  const { availableLanguages } = useLanguage()

  const breakpoints = useBootstrapBreakpoints()

  const { translations, ...data } = useMemo(
    () => processData({ results, language, altUrls, availableLanguages }),
    [results, language, altUrls, availableLanguages]
  )

  return (
    <LanguageProvider
      data={{
        language,
        translations
      }}
    >
      <Ctx.Provider
        value={{
          ...data,
          ...breakpoints
        }}
      >
        {children}
      </Ctx.Provider>
    </LanguageProvider>
  )
}

export const useGlobalData = () => {
  return useContext(Ctx)
}

export const query = graphql`
  query {
    allPrismicMenu {
      nodes {
        ...fragmentPrismicMenu
      }
    }

    allPrismicStrings {
      nodes {
        ...fragmentPrismicStrings
      }
    }

    latestPosts: allPrismicPost (
      sort: {fields: data___date, order: DESC},
      filter: {data: {category: {in: [null, "Almennt"]}}}
      limit: 3
    ) {
      nodes {
        ...prismicPostFragmentFull
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const makeMenu = (results, id) => {
  const menu = results.find(m => m.prismicId === id)

  if (!menu) {
    throw new Error(`cant find menu with id ${id}`)
  }

  const {
    data: { nav_links: items }
  } = menu

  return items
    .filter(item => !item.link.isBroken)
    .map(({ link_name: title, link, sub_menu: subMenu }) => {
      return {
        title: title.text,
        url: link.document ? link.document.url : '',
        subMenu: extractSubMenu(subMenu, results),
        megaMenu: extractMegaMenu(subMenu)
      }
    })
}

const extractMegaMenu = subMenu => {
  const isMegaMenu =
    subMenu.id &&
    subMenu.type === 'megamenu' &&
    subMenu.document &&
    subMenu.document.data

  return isMegaMenu ? subMenu.document.data.body : []
}

const extractSubMenu = (subMenu, results) => {
  const isNormalMenu = subMenu.id && subMenu.type === 'menu'

  return isNormalMenu ? makeMenu(results, subMenu.id) : []
}

const processData = ({ results, language, altUrls, availableLanguages }) => {
  const {
    allPrismicMenu: { nodes: menuNodes },
    allPrismicStrings: { nodes: translationNodes },
    latestPosts: { nodes: latestNewsNode },
    site: {
      siteMetadata: { siteUrl }
    }
  } = results

  const mainMenu = menuNodes
    .filter(menu => langCompare(menu.lang, language))
    .find(menu => menu.data.menu_name === 'mainmenu')

  if (!mainMenu) {
    console.log(menuNodes, language)
    throw new Error('mainmenu not found')
  }

  return {
    translations: makeTranslations(translationNodes),
    mainMenu: makeMenu(menuNodes, mainMenu.prismicId),
    langMenu: makeLangMenu(language, availableLanguages, altUrls),
    latestNews: latestNewsNode.map(mapNewsItem),
    siteUrl
  }
}

export const mapNewsItem = item => {
  const {
    excerpt: { text: excerpt },
    date,
    feature_image: featureImage,
  } = item.data

  const pageTitle = splitCardHeadings(item.data.page_title.text)[0]
  const url = item.url
  const tag = item.tags[0]

  return { excerpt, date, featureImage, pageTitle, url, tag }
}

const makeLangMenu = (language, availableLanguages, altUrls) => {
  return [
    {
      title: language,
      megaMenu: null,
      subMenu: availableLanguages.map(l => {
        const found = altUrls.find(entry => langCompare(entry.lang, l))

        return {
          title: l,
          // todo: find some way of figuring out the frontpage url for this lang
          url: found ? found.url : '/'
        }
      })
    }
  ]
}

/**
 * takes a list of translations from the prismic query and turns it into a data
 * type compatible with our strings in the i18n plugin
 * @param {array} gatsby node list
 */
const makeTranslations = nodes => {
  return nodes.reduce((acc, node) => {
    const {
      lang,
      data: { group_name: groupName, strings }
    } = node

    const langKey = lang.substring(0, 2)

    const keyedStrings = strings.reduce((acc, { key, value: { text } }) => {
      acc[key] = text

      return acc
    }, {})

    return {
      ...acc,
      [langKey]: {
        ...(acc[langKey] || {}),
        [groupName]: keyedStrings
      }
    }
  }, {})
}
