import React from 'react'
import cn from 'classnames'
import styles from './Checkbox.module.scss'

const Checkbox = ({ className, hasError, children, ...props }) => (
  <label className={cn(styles.label, className)}>
    <input type='checkbox' className={styles.input} {...props} />
    <div className={cn(styles.checkbox, {[styles.checkboxHasError]: hasError})} />
    {children}
  </label>
)

export default Checkbox
