import React from 'react'
import styles from './SmallContentCard.module.scss'
import cn from 'classnames'
import { Link } from '@kogk/gatsby-theme-base'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { PostTag } from '@src/components/site'
import defaultThumbnail from '../../../assets/logos/unicef-white-stacked.png'

export default ({ heading, image, link, tag, cardLabel }) => {
  const { t } = useLanguage()
  const img = image.thumbnails.cardpic?.url ? image.thumbnails.cardpic.url : defaultThumbnail
  const imageClasses = cn(
    styles.image,
    {[styles.fallback]: !image.thumbnails.cardpic?.url  }
  )
  return (
    <div className='col-lg-3 d-flex flex-column my-2 my-lg-3'>
      {img && (
        <img className={imageClasses} src={img} />
      )}
      <div
        className={cn(
          styles.content,
          'd-flex flex-column px-3 bg--light-grey h-100 justify-content-between'
        )}
      >
        <span>
          {cardLabel && <p className='parag--date-text'>{cardLabel}</p>}
          {tag && <PostTag tag={tag} className='mb-2' />}
          <div className={styles.text}>{heading}</div>
        </span>
        <Link to={link} className='link link--standard text-uppercase mt-3'>
          {t(`news|read more label`)}
        </Link>
      </div>
    </div>
  )
}
