import React from 'react'
import cn from 'classnames'
import styles from './Forms.module.scss'
import * as fields from '../../sections/DonationForm/fields'
import { Formik } from 'formik'
import {
  Barnvaensamfelog,
  Rettindaskoli,
  Beingreidslur,
  Heimsforeldrar,
  Mumin,
  Tiers
} from '../Forms/Formtype'
import { useLocation } from '@reach/router'

export default ({ formid, thankYouPage }) => {
  const { state } = useLocation()

  const types = {
    barnvaensamfelog: Barnvaensamfelog,
    rettindaskoli: Rettindaskoli,
    heimsforeldrar: Heimsforeldrar,
    beingreidsla: Beingreidslur,
    mumin: Mumin,
    tiers: Tiers
  }

  const Cmp = types[formid]
  return (
    <Formik
      enableReinitialize
      validate={fields.validateAll}
      initialValues={{
        banchName: '',
        branchType: '',
        name: state?.formValues?.name ? state.formValues.name : '',
        contactName: '',
        email: state?.formValues?.email ? state.formValues.email : '',
        noStudents: '',
        phone: state?.formValues?.phone ? state.formValues.phone : '',
        ssn: state?.formValues?.ssn ? state.formValues.ssn : '',
        amount: ''
      }}
    >
      <form
        name={formid}
        method='POST'
        data-netlify='true'
        action={thankYouPage}
      >
        <input type='hidden' name='form-name' value={formid} />
        <Cmp />

      </form>
    </Formik>
  )
}
