import React from 'react'
import cn from 'classnames'
import { Helmet } from 'react-helmet'
import { Footer, Header, NavigationMobile, SEO } from '@cmp/site'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { variables } from '@cmp/templates/Styleguide/sass-variables'
import { useLocation } from '@reach/router'
import { useGlobalData } from '@src/data/global-data'
import styles from './MainLayout.module.scss'

export default ({ className, children, pageTitle, overrideDefaultImage }) => {
  const { t } = useLanguage()

  return (
    <>
      <Meta pageTitle={pageTitle} overrideDefaultImage={overrideDefaultImage} />

      <div className={styles.container}>

        <Header />

        <main id='main-content' className={cn(className, styles.main)}>
          {children}
        </main>

        <Footer className={styles.footer} />
      </div>
    </>
  )
}

const Meta = ({ pageTitle, overrideDefaultImage }) => {
  const { t, language } = useLanguage()
  const { pathname } = useLocation()
  const { siteUrl } = useGlobalData()

  const currentUrl = `${siteUrl}${pathname}`

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <link data-react-helmet='true' rel='icon' href='/favicon.ico' />
        <meta property='og:title' content={pageTitle} />
        <meta property='og:url' content={currentUrl} data-react-helmet='true' />
        <meta
          name='msapplication-TileColor'
          content={variables.color.colors[0].val}
        />
        <meta name='theme-color' content={variables.color.colors[1].val} />
        {/* <!-- Twitter universal website tag code --> */}
        <script>
          {`!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);},
            s.version='1.1',s.queue=[],u=t.createElement(n),u.,
            a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
            twq('init','nyl9o');
            twq('track','PageView')`}
        </script>
        {/* <!-- End Twitter universal website tag code --> */}
        <html lang={language} />
      </Helmet>

      <SEO
        title={pageTitle ? pageTitle : t`site title`}
        og={{
          type: t`meta|og:type`,
          title: pageTitle,
        }}
        ogImage={overrideDefaultImage}
        meta={{
          description: t`meta|description`,
          keywords: t`meta|keywords`
        }}
      />
    </>
  )
}
