import React from 'react'
import styles from './NavigationDesktop.module.scss'
import PropTypes from 'prop-types'
import { Row, Col } from '@kogk/common'
import { makeSlices } from '@kogk/gatsby-theme-prismic'
import { Link } from '@kogk/gatsby-theme-base'
import cn from 'classnames'
import { isExternalLink } from '@src/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons'

const NavigationDesktop = ({ items, title }) => {
  return (
    <section className={styles.navigation} aria-label={title} title={title}>
      <ul>
        {items.map(item => {
          return (
            <MenuItem key={item.title} item={item}>
              {!!item.megaMenu.length && <MegaMenu megaMenu={item.megaMenu} />}
            </MenuItem>
          )
        })}
      </ul>
    </section>
  )
}

export default NavigationDesktop

const itemsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
    subMenu: PropTypes.array,
    megaMenu: PropTypes.array
  })
).isRequired

NavigationDesktop.propTypes = {
  items: itemsPropType,
  title: PropTypes.string
}

const MegaMenu = ({ megaMenu }) => {
  return (
    <div className={styles.submenu}>
      <Row>
        <Col
          className='d-flex'
          col={{ md: 8 }}
          offset={{ lg: 2 }}
        >
        <MenuSlices
          body={megaMenu}
          wrapper={el =>
            <div>
            {el}
          </div>} />
        </Col>
      </Row>
    </div>
  )
}

const MegaMenuColumn = ({ title, items }) => {
  return (
    <>
      <h4 className='eyebrows eyebrows--white'>
        {title}
      </h4>

      <ul>
        {items.map((item, i) => {
          const {
            document: { url: link },
            link_title: linkTitle
          } = item

          return (

            <li key={i} className={styles.subMenuItems}>
              <Link
                className='pr-6 text-uppercase'
                to={link}
              >
                {linkTitle}

                {isExternalLink(link) && (
                  <FontAwesomeIcon
                    icon={faExternalLink}
                  />
                )}
              </Link>
            </li>
        )})}
    </ul>
    </>
  )
}

const MenuItem = ({ item, children }) => (
  <li key={item.url}>
    <Link
      to={item.url}
      className={styles.menuItem}
      activeClassName={styles.menuItemActive}
    >
      <span>
        {item.title}
      </span>
    </Link>

    {children}
  </li>
)

const MenuSlices = makeSlices({
  PrismicMegamenuBodyLinkList: {
    component: MegaMenuColumn,
    dataToProps (data) {
      const {
        primary: { title },
        items
      } = data

      return { title, items }
    }
  }
})
