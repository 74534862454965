import React from 'react'
import styles from './Spinner.module.scss'

// borrowed from https://tobiasahlin.com/spinkit/
export default () => (
  <div className={styles.skChase}>
    {new Array(6).fill(0).map((_,key) => (
      <div key={key} className={styles.skChaseDot}></div>
    ))}
  </div>
)
