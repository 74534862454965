import React from 'react'
import {
  Row,
  Col,
  Container
} from '@kogk/common'
import { SplitHeading } from '@cmp/site'
import cn from 'classnames'
import ReactPlayer from 'react-player/youtube'
import styles from './Video.module.scss'

export default ({ url }) => {
  return (
    <ReactPlayer controls className={styles.video} width='100%' height='100%' url={url.embed_url} />
  )
}
