import React from 'react'
import cn from 'classnames'
import { splitHeadings } from '@src/utils'

export default ({
  children,
  innerClass,
  classNames,
  wrapperClass,
  tag: El,
  ...props
}) => {
  if (typeof children !== 'string') {
    throw new Error('only strings accepted in <SplitHeading>')
  }

  const splitted = splitHeadings(children)

  return (
    <span className={cn(
      {'d-flex flex-column': props.textCol},
      {'align-items-center text-center': props.textCenter},
      classNames
    )}>
      {splitted.map((str, i) =>
        <El key={i}>
          <span key={str}>{str}</span>
        </El>
      )}
    </span>
  )
}
