import React from 'react'

/* eslint max-len: ['error', { 'code': 15000 }] */
const SvgComponent = props => (
  <svg fill={props.fill} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
    <path d='M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z' />
  </svg>

)

export default SvgComponent

// Font Awesome Pro 5.1.0 by @fontawesome - https://fontawesome.com
// License - https://fontawesome.com/license (Commercial License)
