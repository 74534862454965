import React, { Fragment } from 'react'
import { Field } from 'formik'
import styles from '../Forms.module.scss'
import { Input } from '@cmp/site'
import cn from 'classnames'

export default () => {
  return (
    <Fragment>
      <Field
        id='branchName'
        component={InputField}
        type='text'
        name='branchName'
        label='Nafn starfsstöðvar'
      />
      <Field
        id='branchType'
        component={InputField}
        type='text'
        name='branchType'
        label='Tegund starfsstöðvar'
      />
      <Field
        id='name'
        name='name'
        component={InputField}
        type='text'
        label='Nafn tengiliðar'
      />
      <Field
        component={InputField}
        type='email'
        id='email'
        name='email'
        label='Netfang tengiliðar'
      />
      <Field
        component={InputField}
        type='text'
        id='noStudents'
        name='noStudents'
        label='Fjöldi nemenda'
      />
      <button
        type='submit'
        className={cn(styles.spacing, 'button--normal p-3 w-100 text-center')}
      >
        Senda
      </button>
    </Fragment>
  )
}

const InputField = ({ field, form, ...props }) => {
  const error = form.touched[field.name] && form.errors[field.name]

  return (
    <div className={styles.spacing}>
      <Input {...field} {...props} hasError={Boolean(error)} />
      {Boolean(error) && <div className={styles.error}>{error}</div>}
    </div>
  )
}
