import React from 'react'
import { DonationWidget, SubscriptionTiersWidget } from '@cmp/site'

export default ({ data }) => {
  const {
    amounts,
    subscription_tiers: subscriptionTiers,
    frequency,
    title: { text: title }
  } = data.data

  const frequencyData = frequency.map(item => {
    const {
      button_text: { text: buttonText },
      unit: { text: unit },
      text: { html: text },
      link: { url: link }
    } = item

    return { buttonText, unit, text, link }
  })
  if(amounts && amounts.length > 0) {
    return (
      <DonationWidget
      amounts={amounts}
      frequency={frequencyData}
      title={title}
      />
      )
    } else if(subscriptionTiers && subscriptionTiers.length > 0) {
      return (
        <SubscriptionTiersWidget
        tiers={subscriptionTiers}
        standalone={data.standalone}
        />
      )
    } else {
      return null
    }
  }
