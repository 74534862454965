import React, { Fragment, useState, useEffect } from 'react'
import { Field } from 'formik'
import styles from '../Forms.module.scss'
import { Input, Checkbox } from '@cmp/site'
import cn from 'classnames'
import * as fields from '../../../sections/DonationForm/fields'

export default () => {
  const [disabled, setDisabled] = useState(true)
  const handleChange = () => setDisabled(!disabled)


  return (
    <Fragment>
      <Field
        id='name'
        component={InputField}
        type='text'
        name='name'
        label='Nafn'
      />
      <Field
        id='ssn'
        component={InputField}
        type='number'
        name='ssn'
        label='Kennitala'
      />
      <Field
        component={InputField}
        type='email'
        id='email'
        name='email'
        label='Netfang'
      />
      <Field
        component={InputField}
        type='number'
        id='phone'
        name='phone'
        label='Símanúmer'
      />
      <Field
        component={InputField}
        type='number'
        id='amount'
        name='amount'
        label='Ég vil hækka framlagið mitt upp í'
        validate={fields.validateAmount}
      />
      <div style={{ paddingTop: '30px' }} className={'pb-4'} >
        <Field component={TermsField} name='terms' checked={!disabled} onChange={handleChange} />
      </div>

      <button
        disabled={disabled}
        className={cn(styles.spacing, 'button--normal p-3 w-100 text-center')}
      >
        Senda
      </button>
    </Fragment>
  )
}

const TermsField = ({ field, form, ...props }) => {
  return (
    <div>
      <Checkbox {...field} {...props}>
        Ég hef kynnt mér og samþykki
      </Checkbox>{' '}
      <a href='/skilmalar' target='_blank' className='link link--standard'>
        skilmála UNICEF
      </a>
      {Boolean(form.touched.terms && form.errors.terms) && (
        <div className={cn('px-0', styles.error)}>{form.errors.terms}</div>
      )}
    </div>
  )
}

const InputField = ({ field, form, ...props }) => {
  const error = form.touched[field.name] && form.errors[field.name]

  return (
    <div className={styles.spacing}>
      <Input {...field} {...props} hasError={Boolean(error)} />
      {Boolean(error) && <div className={styles.error}>{error}</div>}
    </div>
  )
}
