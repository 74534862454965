import React from 'react'

/* eslint max-len: ['error', { 'code': 15000 }] */
const SvgComponent = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'>
    <path d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z' />
  </svg>

  // Font Awesome Pro 5.1.0 by @fontawesome - https://fontawesome.com
  // License - https://fontawesome.com/license (Commercial License)
)

export default SvgComponent
