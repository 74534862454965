import React from 'react'
import { FancyShow, Container, Row, Col } from '@kogk/common'

export default ({
  shouldMove = true,
  children,
  fullBleed = false,
  ...props
}) => {
  return (
    <FancyShow shouldMove={shouldMove}>
      <section {...props}>
        {condWrap(!fullBleed, children, content => (
          <Container fluid>
            <Row>
              <Col offset={{ lg: 1 }} col={{ lg: 8 }}>
                {content}
              </Col>
            </Row>
          </Container>
        ))}
      </section>
    </FancyShow>
  )
}

const condWrap = (cond, content, wrapper) => {
  return cond ? wrapper(content) : content
}
