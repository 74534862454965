import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faVideo,
  faNewspaper,
  faBookOpen,
} from '@fortawesome/pro-solid-svg-icons'
import cn from 'classnames'

export default ({ tag, className, height='small' }) => {
  const ICONS = {
    Grein: faNewspaper,
    Útskýring: faBookOpen,
    Myndband: faVideo,
  }

  return (
    <div className={cn(className, `tag tag--${height}`)}>
      {ICONS[tag] && (
        <FontAwesomeIcon icon={ICONS[tag]} />
      )}
      <span>{tag}</span>
    </div>
  )
}
