import React from 'react'
import styles from './ContentCard.module.scss'
import { Link } from '@kogk/gatsby-theme-base'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import cn from 'classnames'
import defaultThumbnail from '../../../assets/logos/unicef-white-stacked.png'
import Img from 'gatsby-image'
import { handleImgixUrl } from '@kogk/common'
import { PostTag } from '@src/components/site'

export default ({
  heading,
  image,
  link,
  linkTitle,
  text,
  isDocumentCard,
  cardLabel,
  tag,
}) => {
  const { t } = useLanguage()
  const fluid = image.url ? handleImgixUrl(image.url).gatsbyImageFluid() : null
  const mobileDocImg = image.thumbnails.smaller?.url
    ? image.thumbnails.smaller.url
    : null
  const mobileCardImg = isDocumentCard ? mobileDocImg : image.url
  const linkName = linkTitle ? linkTitle : t(`news|read more label`)

  const imageClasses = cn(
    styles.image,
    { [styles.smallerMobileImage]: isDocumentCard },
    'd-block d-lg-none'
  )

  return (
    <div
      className={cn(
        styles.card,
        'col-lg-6 d-flex my-3 flex-column flex-lg-row'
      )}
    >
      {fluid ? (
        <>
          <Img
            className={cn(styles.image, 'd-none d-lg-inline')}
            fluid={fluid}
          />
          <img className={imageClasses} src={mobileCardImg} />
        </>
      ) : (
        <img className={styles.fallback} src={defaultThumbnail} />
      )}
      <div
        className={cn(
          'd-flex flex-column justify-content-between bg--light-grey',
          styles.contentContainer
        )}
      >
        <span>
          {cardLabel && <p className='parag--date-text'>{cardLabel}</p>}
          {tag && <PostTag tag={tag} className='mb-3' />}
          <h5>{heading}</h5>
          {text && <p className={styles.text}>{text}</p>}
        </span>
        <Link to={link} className='link link--standard text-uppercase'>
          {linkName}
        </Link>
      </div>
    </div>
  )
}
