import React, { useState } from 'react'
import {
  Row,
  Col,
  Container
} from '@kogk/common'
import { Link } from '@kogk/gatsby-theme-base'
import cn from 'classnames'
import styles from './NavigationMobile.module.scss'
import { AngleDown } from '@src/assets/icons'
import { makeSlices } from '@kogk/gatsby-theme-prismic'
import { isExternalLink } from '@src/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink, faAngleDown } from '@fortawesome/pro-solid-svg-icons'

export default ({ items }) => {
  return (
    <Col
      col={12}
      className={cn(
        'd-flex flex-column',
        styles.openContainer
      )}>

      {items.map((item, key) => {
        const {
          title,
          url,
          megaMenu
        } = item

        return (
          <div key={key} className={styles.navLink}>
            <MenuItem
              key={item.title}
              item={item}
              index={key}
            >
              {!!item.megaMenu.length &&
                <MegaMenu
                  megaMenu={item.megaMenu}
                />
              }
            </MenuItem>
          </div>
        )
      })}
    </Col>
  )
}

const MegaMenu = ({ megaMenu, classNames }) => {
  return (
    <Row className={classNames}>
      <div className='container-black w-100 py-3 px-2 mb-3'>
        <Col
          className='d-flex flex-column '
          col={8}
          offset={{ xl: 2 }}
        >
          <MenuSlices
            body={megaMenu}
            wrapper={el =>
              <div>
                {el}
              </div>} />
        </Col>
      </div>
    </Row>
  )
}

const MegaMenuColumn = ({ title, items }) => {
  return (
    <>
      <h4 className='eyebrows eyebrows--white'>
        {title}
      </h4>

      <ul className={styles.submenu}>
        {items.map((item, i) => {
          const {
            document: { url: link },
            link_title: linkTitle
          } = item

          return (

            <li key={i} className={cn(
              styles.submenuItems,
              'pb-1'
            )}>
              <Link
                className='text-uppercase'
                to={link}
              >
                {linkTitle}
                {isExternalLink(link) && (
                  <FontAwesomeIcon
                    className={styles.externalIcon}
                    icon={faExternalLink}
                  />
                )}
              </Link>
            </li>
          )
        })}
      </ul>
    </>
  )
}

const MenuItem = ({ item, children, index }) => {
  const [openItem, setOpenItem] = useState(null)

  const toggleSubmenu = (index) => {
    index !== openItem ? setOpenItem(index) : setOpenItem(null)
  }

  return (
    <div key={item.url}>
      {!!item.megaMenu.length
        ?
        <button
          onClick={() => toggleSubmenu(index)}
          className={
            cn(
              'w-100',
              { [styles.rotated]: index === openItem }
            )
          }
        >
        <div className='px-2 pb-2 d-flex justify-content-between align-items-center'>
          <span className={cn(
            { [styles.activeLink]: index === openItem },
            styles.navItem
          )}>
            {item.title}
          </span>
          {!!item.megaMenu.length &&
            <span className={styles.angle}>
              <AngleDown />
            </span>
          }
        </div>
          <div className={cn(
            { [styles.collapsed]: index !== openItem }
          )}>
            {children}
          </div>
        </button>

        : <Link
          to={item.url}
          className='pb-3 px-2 d-flex justify-content-between align-items-center'>
          <span className={cn(
            { [styles.activeLink]: index === openItem },
            styles.navItem
          )}>
            {item.title}
          </span>
        </Link>

      }

    </div>
  )
}

const MenuSlices = makeSlices({
  PrismicMegamenuBodyLinkList: {
    component: MegaMenuColumn,
    dataToProps(data) {
      const {
        primary: { title },
        items
      } = data

      return { title, items }
    }
  }
})