import styleVars from './sass-variables.module.scss'

const extractVariableParts = key => {
  // variables are output capitalized in format: typeGroupNameOfThingMaybeWithNumber100
  // now this is pretty hacky :P
  const [type, group, ...rest] = key.split(/(?=[A-Z1-9])/)

  return [
    type.toLowerCase(),
    group.toLowerCase(),
    '$' + rest.map(r => r.toLowerCase()).join('-')
  ]
}

const styleList = Object.entries(styleVars)

export const variables = styleList.reduce((acc, [key, val]) => {
  const [type, group, name] = extractVariableParts(key)

  if (!acc[type]) {
    acc[type] = {}
  }

  if (!acc[type][group]) {
    acc[type][group] = []
  }

  acc[type][group].push({
    name,
    val
  })

  return acc
}, {})
