import React from 'react'
import cn from 'classnames'
import styles from './Input.module.scss'

const Input = ({ className, id, name, label, value, hasError, ...props }) => {
  id = id || `input_${name}`

  return (
    <div className={cn(styles.container, {[styles.containerHasError]: hasError}, className)}>
      <input
        id={id}
        name={name}
        value={value}
        className={styles.input}
        {...props}
      />
      <label htmlFor={id} className={cn(styles.label, {[styles.labelTop]: value})}>
        {label}
      </label>
    </div>
  )
}

export default Input
