import React, { Fragment } from 'react'
import { Field } from 'formik'
import styles from '../Forms.module.scss'
import { Input } from '@cmp/site'
import cn from 'classnames'
import { Row, Col, Container } from '@kogk/common'

export default () => {
  return (
    <Container fluid className='px-0'>
      <Row>
        <Col col={12}>
          <Field component={InputField} name='amount' label='Upphæð' />
        </Col>
        <Col col={12}>
          <Field component={InputField} name='email' label='Netfang' />
        </Col>
        <Col col={6}>
          <Field component={InputField} name='phone' label='Sími' />
        </Col>
        <Col col={6}>
          <Field component={InputField} name='ssn' label='Kennitala' />
        </Col>
      </Row>
      <Row>
        <Col col={6}>
          <Field component={InputField} name='bankCode' label='Banki' />
        </Col>
        <Col col={6}>
          <Field component={InputField} name='bankAccGroup' label='Höfuðbók' />
        </Col>
        <Col col={12}>
          <Field
            component={InputField}
            name='bankAccount'
            label='Reikningsnúmer'
          />
        </Col>
        <Col col={12}>
          <button
            className={cn(styles.spacing, 'button--normal p-3 w-100 text-center')}
          >
            Senda
          </button>
        </Col>
      </Row>
    </Container>
  )
}

const InputField = ({ field, form, ...props }) => {
  const error = form.touched[field.name] && form.errors[field.name]

  return (
    <div className={styles.spacing}>
      <Input {...field} {...props} hasError={Boolean(error)} />
      {Boolean(error) && <div className={styles.error}>{error}</div>}
    </div>
  )
}
