import React, { useState } from 'react'
import cn from 'classnames'
import styles from './RadioButton.module.scss'

export default ({ className, children, ...props }) => (
  <label className={cn(className, styles.label)}>
    <input type='radio' className={styles.input} {...props} />
    <div className={styles.border} />
    <div className={styles.checkbox} />
    {children}
  </label>
)
